export const chartExplainerSlugKey: { [key: string]: string } = {
    speciesrichness: 'species-richness',
    evolutionarydiversity: 'evolutionary-diversity',
    fishcommercialvalue: 'fish-commercial-value',
    fishfoodchainintegrity: 'fish-food-chain-integrity',
    fishprevalenceofsensitivespecies: 'fish-prevalance-of-sensitive-species',
    bfd: 'bacterial-functional-diversity',
    ffd: 'fungal-functional-diversity',
    fb_ratio: 'soil-fungal-to-bacterial-ratio',
};
